import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/TexasMassageInfo-Prod/node_modules/gatsby-theme-docz/src/base/Layout.js";
import styles from '../src/styles/styles.css';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "an-introduction"
    }}>{`An Introduction`}</h1>
    <br />
    <p>{`TDLR implementation of `}<strong parentName="p">{`Tex. Occ. Code 455.161`}</strong>{` and `}<strong parentName="p">{`455.2035`}</strong>{` has caused a great deal of confusion and vulnerability within the massage school community.
The buggy rollout of the PALMS system and TDLR’s conflicting statements have only made this situation worse. The numerous attempts from schools to work with
TDLR to clear up this confusion have not led to any significant improvement, and in many cases, have made the situation worse. New statements made by TDLR
conflict with their previously released statements.`}</p>
    <div style={{
      "color": "#D32F2F",
      "marginBottom": "16px"
    }}>
  This situation is dangerous to the public, detrimental to a student’s education, and exposes massage schools to unnecessary litigation and regulatory action.
    </div>
    <p>{`This document is designed to provide a set of conclusions that massage schools can use. It is done by reconciling TDLR’s statements and actions against the laws
and truths of reality. Every attempt was done to be mindful that the highest priorities are placed on public safety and the educational quality delivered to
students. To keep it simple, the influence of federal regulations such as FERPA were excluded, as not everyone is subject to them. However, this reconciliation
should cover those cases effectively as well.`}</p>
    <p>{`I must stress that much of this would have been avoided if TDLR (and lawmakers) had closer cooperation with massage schools, which, due to these new bills,
are easily the single largest users of Texas’ massage regulatory services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      